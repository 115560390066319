import React from "react";
import { Quote } from "lucide-react";

export default function Welcome() {
  return (
    <section
      id="bienvenida"
      className="py-20 bg-gradient-to-br from-gray-100 to-white dark:from-gray-900 dark:to-[#2a1f3d]"
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          <div className="w-full md:w-1/2 space-y-6">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-[#fb4414] dark:text-white leading-tight">
              Bienvenid@ a nuestra Academia
            </h1>

            {/* Imagen para móvil */}
            <div className="md:hidden mt-8 mb-8">
              <div className="relative">
                <div className="absolute inset-0 bg-[#fb4414] dark:bg-[#5d4b8a] rounded-lg transform rotate-3 scale-105 opacity-80"></div>
                <img
                  src="/welcome.svg"
                  alt="Imagen de bienvenida"
                  className="rounded-lg shadow-2xl w-full h-auto relative z-10 transform transition-transform duration-300 hover:scale-105"
                />
              </div>
            </div>

            <div className="relative bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <Quote className="absolute text-[#fb4414] top-4 left-4 w-8 h-8 opacity-20" />
              <p className="text-lg md:text-xl text-gray-700 dark:text-gray-300 italic pl-8">
                El conocimiento es el puente hacia tus sueños. Cada esfuerzo que
                pongas hoy en tu educación te acerca un paso más a ese futuro
                que deseas. No te rindas, sigue aprendiendo, porque el verdadero
                éxito nace del compromiso y la pasión por alcanzar tu mejor
                versión.
              </p>
              <div className="flex items-center mt-4">
                <div className="w-16 h-16 rounded-full overflow-hidden">
                  <img
                    src="assets/founder-damian.webp"
                    alt="Damián Gómez, Fundador de Akadamian"
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="ml-4">
                  <p className="font-semibold text-gray-900 dark:text-white">
                    Damián Gómez
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Fundador de Akadamian
                  </p>
                </div>
              </div>
            </div>
            <a
              href="#conocenos"
              className="inline-block bg-[#fb4414] hover:bg-[#d63a0f] text-white font-bold py-3 px-8 rounded-full transition-colors duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl"
            >
              Descubre más
            </a>
          </div>

          {/* Imagen para desktop */}
          <div className="hidden md:block md:w-1/2 mt-8 md:mt-0">
            <div className="relative">
              <div className="absolute inset-0 bg-[#fb4414] dark:bg-[#5d4b8a] rounded-lg transform rotate-3 scale-105 opacity-80"></div>
              <img
                src="/welcome.svg"
                alt="Imagen de bienvenida"
                className="rounded-lg shadow-2xl w-full h-auto relative z-10 transform transition-transform duration-300 hover:scale-105"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
